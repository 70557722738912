










































import Field from '@/components/questionnaire/Field.vue'
import { errorNotification, warningNotification } from '@/includes/NotificationService'

import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    Field,
    ValidationObserver,
  },
})
export default class Form extends Vue {
  @Prop({ required: true, type: Object }) form!:any

  @Prop({ type: Function }) onSend!:() => any

  send() {
    if ((this.$refs as any).field) {
      Promise.all((this.$refs as any).field.map((field:any) => field.$refs.field.validate()))
        .then(res => !res.map(ms => ms.valid).includes(false) ? this.onSend(): '')
        .catch(errorNotification);
    } else {
      warningNotification(this.$t('form_is_empty').toString())
    }
  }
}
